export default [
  {
    path: '/',
    redirect: { name: 'nav', params: { levels: ['rent'] } },
  },
  {
    path: '/agents/:organizationId/:id',
    component: () => import('./components/routes/agent/Root.vue'),
    children: [
      {
        path: '',
        name: 'agentHome',
        component: () => import('./components/routes/agent/Profile.vue'),
      },
      {
        path: 'profile',
        alias: '',
        name: 'agentHome',
        component: () => import('./components/routes/agent/Profile.vue'),
      },
      {
        path: 'list',
        name: 'agentList',
        component: () => import('./components/routes/agent/List.vue'),
      },
      {
        path: 'team',
        name: 'team',
        component: () => import('./components/routes/agent/Team.vue'),
      },
    ],
  },
  {
    path: '/:levels*/detail/:id',
    name: 'detail',
    component: () => import('./pages/DetailView.vue'),
  },
  {
    path: '/:levels*/list',
    name: 'list',
    component: () => import('./pages/ListView.vue'),
  },
  {
    path: '/:levels*',
    name: 'nav',
    component: () => import('./pages/NavView.vue'),
    alias: '/:levels*',
  },
  {
    path: '/404',
    // path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('./pages/NotFound.vue'),
  },
]
