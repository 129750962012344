<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const searchType = computed(() => route.params.levels?.[0])
</script>

<template>
  <header class="top">
    <div class="header frame u-row">
      <a class="back u-row" @click="router.go(-1)">
        <FontAwesomeIcon :icon="['fa', 'chevron-left']" />
      </a>
      <RouterLink to="/">
        <img class="logo" :src="'/images/logo.png'" alt="Open Room" title="Open Room" />
      </RouterLink>
      <nav class="tabs u-row">
        <a
          class="tab u-col"
          @click="
            router.push({
              name: route.name == 'list' ? route.name : route.name == 'detail' ? 'list' : 'nav',
              params: {
                ...route.params,
                levels: [type, ...(route.params.levels?.slice(1) ?? [])],
              },
            })
          "
          :class="{ active: searchType == type }"
          v-for="type of ['rent', 'buy', 'invest']"
          :key="type"
        >
          <span class="w7">{{ t(type) }}</span>
        </a>
      </nav>
    </div>
  </header>
</template>

<style scoped>
.top {
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 128, 204, 0.1);
}
.header {
  margin: 0 auto;
  background-color: var(--cl-white);
}
.header .back {
  width: 48px;
  height: 30px;
  margin-right: 10px;
}
.header .back svg {
  width: 10px;
  height: 16px;
  color: var(--cl-black-40);
}
.header .logo {
  height: 32px;
}
.header .tabs {
  margin-left: auto;
}
.header,
.header .tabs,
.header .tabs .tab {
  height: 80px;
}
.header .tabs .tab {
  justify-content: center;
  text-align: center;
  min-width: 80px;
  border-top: solid 3px transparent;
  border-bottom: solid 3px transparent;
  color: #9da29f;
}
.header .tabs .tab.active {
  color: var(--cl-black);
  border-bottom-color: var(--cl-blue);
}
@media (max-width: 1060px) {
  .header .desktop {
    display: none;
  }
  .header .back {
    width: 16px;
  }
  .header {
    min-height: 48px;
    justify-content: space-between;
  }
  .header .logo {
    width: 100px;
    min-width: 100px;
    max-width: 30%;
    height: auto;
  }
  .header .tabs {
    margin-left: unset;
  }
  .header .tabs .tab {
    font-weight: 700;
    min-width: 24px;
    width: 50px;
    margin-left: 0;
  }
  .header,
  .header .tabs,
  .header .tabs .tab {
    height: 64px;
  }
}
</style>

<i18n lang="yaml">
en:
  rent: 'Rent'
  buy: 'Buy'
  invest: 'Invest'
ja:
  rent: '借りる'
  buy: '買う'
  invest: '投資'
</i18n>
