<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

// @ts-ignore
import { SchemaOrgBreadcrumb } from '@unhead/schema-org/vue'
import { usePageLevelInit } from '@/composables/pageLevel'
import { use as usePageLevel } from '@/stores/pageLevel'

import type { Breadcrumb } from '@/stores/pageLevel'

const page = usePageLevel()
const props = defineProps<{ breadcrumbs?: Breadcrumb[] }>()

usePageLevelInit()
const breadcrumbs = computed(() => props.breadcrumbs ?? page.breadcrumbs)
</script>
<template>
  <nav class="breadcrumb" aria-label="Breadcrumb">
    <SchemaOrgBreadcrumb
      as="ul"
      class="frame u-row"
      :item-list-element="breadcrumbs.map((b) => ({ item: b.path, name: b.title }))"
    >
      <li><RouterLink to="/">物件検索</RouterLink></li>
      <template v-for="({ path, level, title }, i) of breadcrumbs" :key="path">
        <FontAwesomeIcon :icon="['fas', 'chevron-right']" />
        <li aria-current="page" v-if="i == breadcrumbs.length - 1">
          {{ title }}
        </li>
        <li v-else>
          <RouterLink
            :to="{
              name: level == 'city' ? 'list' : 'nav',
              params: { levels: path.split('/').filter((s) => s) },
            }"
            v-if="path"
          >
            {{ title }}
          </RouterLink>
          <span v-else>{{ title }}</span>
        </li>
      </template>
    </SchemaOrgBreadcrumb>
  </nav>
</template>
<style scoped>
.breadcrumb {
  flex-shrink: 0;
  align-self: start;
  border-bottom: solid 1px var(--cl-black-10);
  width: 100%;
  overflow-x: auto;
}
.breadcrumb ul {
  font-size: 12px;
  height: 50px;
}
.breadcrumb ul > *:not(:first-child) {
  margin-left: 12px;
}
.breadcrumb ul svg {
  color: var(--cl-black-40);
}
.breadcrumb ul li {
  position: relative;
  white-space: nowrap;
}
.breadcrumb ul li a {
  color: var(--cl-blue);
}
</style>
