<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { event } from 'vue-gtag'

import sentry from '@/modules/sentry'
import Popup from '@/modules/popup'

import UISpinner from '@/components/ui/Spinner.vue'

import itemClose from '@/components/popups/items/close.vue'

import { use as useForm } from '@/stores/form'

import type { AgentWithProfiles } from '@/types/profiles.d'

const props = defineProps(['app', 'agent', 'documentId'])

const store = useForm()

store.reset()

store.$patch({
  userId: props.agent.id,
  organizationId: props.agent.organizationId,
  documentId: props.documentId,
})

const submitting = ref(false)
const submitted = ref(false)

const inputEmail = ref<HTMLInputElement | null>(null)
const inputPhone = ref<HTMLInputElement | null>(null)
const form = ref<HTMLFormElement | null>(null)

onMounted(() => {
  form.value?.focus()
})

watch(
  () => store.email,
  () => inputEmail.value?.setCustomValidity('')
)
watch(
  () => store.phone,
  () => inputPhone.value?.setCustomValidity('')
)

function getAvatar(agent: AgentWithProfiles): string {
  return agent.profile.avatar?.data
    ? 'data:image/png;base64,' + agent.profile.avatar.data
    : '/images/avatar.png'
}

function onError(err: { code: 'INVALID_PHONE' | 'INVALID_EMAIL' }) {
  if (err.code == 'INVALID_EMAIL') {
    inputEmail.value?.setCustomValidity('メールアドレスが正しくありません')
  } else if (err.code == 'INVALID_PHONE') {
    inputPhone.value?.setCustomValidity('電話番号が正しくありません')
  }
  form.value?.reportValidity()
}

function validateForm() {
  return form.value?.reportValidity()
}

async function submit() {
  submitting.value = true

  store.$patch({ confirmEmail: store.email })

  if (store.purpose !== 'その他問い合わせ') {
    store.$patch({ notes: '' })
  }

  try {
    if (!validateForm()) {
      event('submit_invalid_inquiry')
      return
    }

    store.requestedAt = new Date().toISOString()

    return await store.submit({ app: props.app }).then((resp) => {
      if (resp.code === 'email_verification_needed') {
        Popup.hide()
        return Popup.show('inquiryValidation', { app: props.app, agent: props.agent })
      } else if (resp.code == 'succeeded') {
        event('submit_inquiry', {
          enum: store.phone ? 'with_phone' : 'default',
        })

        Popup.hide()
        return Popup.show('inquirySuccess', {
          title: '送信しました',
          message: props.agent.company.general?.name + 'の担当者が確認の上、<br/>ご回答いたします',
        })
      } else if (resp.code == 'failed') {
        onError(resp.error)
      }
    })
  } catch (err) {
    sentry.captureException(err)
  } finally {
    submitting.value = false
    submitted.value = true
  }
}
</script>

<template>
  <div class="p-inquiry">
    <item-close @click="$popup.hide()" />

    <h1 class="h2 t-center w7">担当者へのお問い合わせ</h1>

    <div class="agent u-row">
      <div class="avatar" :style="{ backgroundImage: 'url(' + getAvatar(props.agent) + ')' }" />
      <div class="right u-col">
        <p class="fullname h4 w7">
          {{ props.agent.profile.personal?.fullname }}
        </p>
        <div class="company">
          <FontAwesomeIcon :icon="['fas', 'building']" />
          <span>{{ props.agent.company.general?.name }}</span>
        </div>
      </div>
    </div>

    <form ref="form" :class="{ submitted }" novalidate @submit.prevent>
      <div class="containerForm">
        <h2 class="h4 w7">お問い合わせ内容<span class="required">必須</span></h2>
        <div class="options form u-col">
          <div class="u-row option">
            <input
              type="radio"
              id="reason1"
              name="reason"
              value="物件を見学したい"
              v-model="store.purpose"
              required
            />
            <label for="reason1">物件を見学したい</label>
          </div>
          <div class="u-row option">
            <input
              type="radio"
              id="reason2"
              name="reason"
              value="もっと物件の写真がみたい"
              v-model="store.purpose"
            />
            <label for="reason2">もっと物件の写真がみたい</label>
          </div>
          <div class="u-row option">
            <input
              type="radio"
              id="reason3"
              name="reason"
              value="初期費用について知りたい"
              v-model="store.purpose"
            />
            <label for="reason3">初期費用について知りたい</label>
          </div>
          <div class="u-row option">
            <input
              type="radio"
              id="reason4"
              name="reason"
              value="設備や周辺環境などについて詳しく聞きたい"
              v-model="store.purpose"
            />
            <label for="reason4">設備や周辺環境などについて詳しく聞きたい</label>
          </div>
          <div class="u-row option">
            <input
              type="radio"
              id="reason5"
              name="reason"
              value="似た条件の物件を探してほしい"
              v-model="store.purpose"
            />
            <label for="reason5">似た条件の物件を探してほしい</label>
          </div>
          <div class="u-row option">
            <input
              type="radio"
              id="reason6"
              name="reason"
              value="その他問い合わせ"
              v-model="store.purpose"
            />
            <label for="reason6">その他問い合わせ（詳細記入必須）</label>
          </div>
          <div class="details" v-if="store.purpose === 'その他問い合わせ'">
            <textarea v-model="store.notes" placeholder="自由記載" required></textarea>
          </div>
        </div>

        <h2 class="h4 w7">個人情報</h2>
        <div class="form u-col">
          <div class="field u-col">
            <label class="u-row">
              <span class="cl-gray-dark w7">氏名</span>
              <span class="required">必須</span>
            </label>
            <div class="multi u-row">
              <input
                type="text"
                class="i-box u-flex"
                required
                name="lastName"
                placeholder="森山"
                v-model="store.lastName"
              />
              <input
                type="text"
                class="i-box u-flex"
                required
                name="firstName"
                placeholder="太郎"
                v-model="store.firstName"
              />
            </div>
          </div>
          <div class="field u-col">
            <label class="u-row">
              <span class="cl-gray-dark w7">Eメール</span>
              <span class="required">必須</span>
            </label>
            <input
              ref="inputEmail"
              type="email"
              class="i-box u-flex"
              required
              name="email"
              placeholder="demo@openrm.co.jp"
              v-model="store.email"
            />
          </div>
          <div class="field u-col">
            <label class="u-row">
              <span class="cl-gray-dark w7">電話番号</span>
            </label>
            <input
              ref="inputPhone"
              type="text"
              class="i-box u-flex"
              name="phone"
              placeholder="000-0000-0000"
              v-model="store.phone"
            />
          </div>
        </div>
      </div>

      <p class="policy t-center cl-gray">
        <a class="txt-link small" href="https://openrm.co.jp/policy.html" target="_blank"
          >個人情報の取り扱い</a
        ><span class="small">同意の上、送信してください</span>
      </p>

      <div class="u-row buttons">
        <UISpinner v-if="submitting" />
        <template v-else>
          <a class="btn secondary" @click="$popup.hide()"><span>キャンセル</span></a>
          <button type="submit" class="btn default" @click="submit">
            <span>次へ</span>
          </button>
        </template>
      </div>
    </form>
  </div>
</template>

<style scoped>
.p-inquiry {
  margin: auto;
  padding: 33px 40px;
  background: white;
}

@media (max-width: 750px) {
  .p-inquiry {
    width: 100%;
    border-radius: 0 !important;
  }
}

.containerForm {
  overflow: auto;
  min-height: 200px;
}
.containerForm .h4:first-child {
  margin-top: 0;
}

.form {
  background-color: var(--cl-gray-50);
  padding: 24px 32px;
}
.form:not(.submitted) .i-box:invalid {
  border-color: var(--cl-black-20);
}
.form .i-box:focus {
  border-color: var(--cl-orange) !important;
}

.options {
  align-items: baseline;
}
.options .option {
  height: 19px;
  margin-top: 12px;
}

input[type='radio'] {
  margin: 0px 10px 0px 0px;
}

.h4 {
  margin: 32px 0px 16px;
}

.field {
  width: 100%;
  align-items: baseline;
  margin-top: 12px;
}

.field label {
  margin-bottom: 8px;
}

.field .multi {
  max-width: 100%;
}

.field .multi input:not(:first-child) {
  margin-left: 8px;
}

.field > input {
  width: 100%;
}

.details {
  width: 100%;
}
.details textarea {
  border-radius: 2px;
  border: 1px solid var(--cl-powder-gray);
  margin-top: 12px;
  padding: 10px 12px;
  width: 100%;
}

.required {
  background: var(--cl-orange);
  color: var(--cl-white);
  border-radius: 2px;
  font-size: 10px;
  padding: 1px 4px;
  margin-left: 6px;
}

.txt-link {
  display: inline-block;
  margin-top: 32px;
}

.buttons {
  justify-content: space-around;
  margin: 16px 27px;
}
.btn {
  max-width: 200px;
  min-width: 150px;
  width: 100%;
}
.btn + .btn {
  margin-left: 8px;
}
.agent {
  margin: 12px 12px 16px 12px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--cl-black-10);
}
.agent .avatar {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
}
.agent,
.agent .right {
  align-items: unset;
}
.agent .right .fullname {
  margin: 0 0 8px 0;
}
.agent .right .company {
  color: var(--cl-black-60);
}
.agent .right .company svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: sub;
}
</style>
