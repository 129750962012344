<script setup lang="ts">
import itemClose from '@/components/popups/items/close.vue'
import IconCheck from '@/assets/icons/check.svg?component'

const props = defineProps(['title', 'message', 'link'])
</script>

<template>
  <div class="p-inquiry-success">
    <item-close @click="$popup.hide()" />

    <h1 class="h2 w7 t-center">担当者より追ってご連絡があります</h1>

    <div class="message u-col">
      <IconCheck class="check" />
      <h2 class="h3 w7">{{ props.title }}</h2>
      <p v-html="props.message" class="t-center"></p>
    </div>

    <template v-if="props.link">
      <p class="info">こちらのお客様マイページから引き続き物件情報をいつでもご覧になれます。</p>

      <a class="btn basic outline" :href="props.link">
        <span>お客様マイページを開く</span>
      </a>
    </template>

    <button type="button" class="btn basic outline" @click="$popup.hide()" v-else>
      <span>閉じる</span>
    </button>
  </div>
</template>

<style scoped>
.p-inquiry-success {
  margin: auto;
  padding: 33px 40px;
  background: white;
  position: relative;
}

.message {
  background-color: var(--cl-gray-50);
  padding: 32px 24px;
  margin: 27px 0px 40px 0px;
}

.message svg {
  fill: var(--cl-green);
  width: 40px;
}

.message .h3 {
  margin: 8px 0px 16px;
}

.info {
  margin-bottom: 40px;
}

.btn {
  width: 212px;
  margin: auto;
}

@media (max-width: 750px) {
  .p-inquiry-success {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
  }
}
</style>
