import { defineStore } from 'pinia'

import i18n from '@/modules/i18n'

type GroupedChildPages = Record<string, Record<string, Array<{ path: string; name: string }>>>

type Level = 'type' | 'buildingType' | 'prefecture' | 'city'
interface Meta {
  name: string
  path?: string
  slug?: string
  title: string
}

export interface Breadcrumb extends Meta {
  level?: Level
}

interface Page {
  name: string
  slug: string
  path: string
  level: Level
  kana: string
  group?: string
}

type PageLevel = Record<Level, string>

interface LinkedPage extends Page {
  parents: Page[]
  children: Page[]
}

export const use = defineStore('pageLevel', {
  state: () => ({
    levels: [] as string[],
    data: null as LinkedPage | null,
    current: null as Meta | null, // used for each page in the list page
  }),
  getters: {
    path(): string {
      return this.levels.join('/')
    },
    type(): string {
      return this.levels[0]
    },
    buildingType(): string {
      return this.levels[1]
    },
    typeLabel(): string {
      return i18n.global.t('type.' + this.levels[0])
    },
    title(): string | null {
      if (this.data) {
        if (this.data.level == 'type') return this.data.name
        return this.data.name + 'の' + this.typeLabel
      }
      return null
    },
    pageLevel(): PageLevel {
      return this.breadcrumbs.reduce((acc, b) => {
        if (b.level) acc[b.level] = i18n.global.t(b.level + '.' + b.slug, b.name)
        return acc
      }, {} as PageLevel)
    },
    breadcrumbs(): Breadcrumb[] {
      if (this.data) {
        const parents: Breadcrumb[] = this.data.parents.map((p, i) => ({
          ...p,
          title: i == 0 ? p.name : p.name + 'の' + this.typeLabel,
        }))
        const crumbs: Breadcrumb[] = parents
          .concat({
            path: this.path,
            title: this.title as string,
            slug: this.data.slug,
            name: this.data.name,
            level: this.data.level,
          })
          .map((p) => ({ ...p, path: p.path?.replace(new RegExp('^([^/])'), '/$1') }))
        return this.current ? crumbs.concat(this.current) : crumbs
      }
      return []
    },
    level(): Level | null {
      return this.breadcrumbs[this.breadcrumbs.length - 1]?.level ?? null
    },
    children(): GroupedChildPages | null {
      if (this.data) {
        const levels: GroupedChildPages = this.data.children.reduce((levels, page: Page) => {
          const group = page.group?.match(/[市区]$/) ? page.group : String(null)

          if (!levels[page.level]) levels[page.level] = {}
          if (!levels[page.level][group]) levels[page.level][group] = []

          // TODO alpha
          const path = page.level == 'city' ? page.path + '/list' : page.path

          levels[page.level][group].push({ ...page, path })

          return levels
        }, {} as GroupedChildPages)

        // FIXME we want to put null group at last but in a better way
        for (const level in levels) {
          const { null: other, ...groups } = levels[level]
          levels[level] = { ...groups, null: other }
        }

        return levels
      }
      return null
    },
  },
})
