<script setup lang="ts">
import { useRoute, RouterView } from 'vue-router'
import { useHead } from '@unhead/vue'

// @ts-ignore
import { SchemaOrgOrganization } from '@unhead/schema-org/vue'

import i18n from '@/modules/i18n'

import LayoutPopup from '@/components/LayoutPopup.vue'
import LayoutToast from '@/components/LayoutToast.vue'

const route = useRoute()

useHead({
  titleTemplate: '%s | オープンルーム',
  meta: [
    import.meta.env.DEV ? { name: 'robots', content: 'none' } : {},
    { name: 'og:type', content: 'website' },
    { name: 'og:locale', content: () => i18n.global.locale.value },
  ],
  htmlAttrs: {
    lang: i18n.global.locale.value,
  },
  templateParams: {
    schemaOrg: {
      host: import.meta.env.VITE_SEO_ORIGIN,
      path: route.path,
      inLanguage: i18n.global.locale.value,
    },
  },
})
</script>

<template>
  <ClientOnly>
    <LayoutPopup />
    <LayoutToast />
  </ClientOnly>
  <Suspense><RouterView /></Suspense>

  <!-- schema.org -->
  <SchemaOrgOrganization
    name="Open Room Inc."
    logo="https://openrm.co.jp/assets/common/images/logos/black.svg"
    :same-as="['https://openrm.co.jp/', 'https://www.facebook.com/openrm']"
  />
</template>

<style scoped>
.l-view:not(.reset) {
  padding: 16px 16px 0 16px;
  max-width: 600px;
  margin: 0 auto;
  overflow-y: auto;
  padding-bottom: 20px;
}
.l-popup {
  z-index: 2;
}
</style>
